import * as React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import Events from "./events";
import Index from "./index";
import PamphletsForCulture from "./pamphlets-for-culture";
import PrivacyPolicy from "./privacy-policy";
import Pamphlet from "../templates/pamphlet";

const NotFoundPage = () => (
  <>
    <h1>Page not found!</h1>
  </>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    events: Events,
    homepage: Index,
    pamphlet: Pamphlet,
    pamphlets_for_culture: PamphletsForCulture,
    privacy_policy: PrivacyPolicy,
  },
});
